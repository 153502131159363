import TronWeb from "tronweb";
const Cryptr = require('cryptr');

const env = process.env
const cryptr = new Cryptr(env.REACT_APP_SECRET);

let constantes = {}
constantes.proxy = "https://cors.brutusservices.com/";
constantes.BRUTUS_API = constantes.proxy + process.env.REACT_APP_API_URL + "api/v1/"

async function rentResource(wallet_orden, recurso, cantidad, periodo, temporalidad, precio, signedTransaction) {

    if (recurso === "bandwidth" || recurso === "band") {
      recurso = "band"
    } else {
      recurso = "energy"
    }
  
    let time = periodo
  
    if (temporalidad === "h" || temporalidad === "hour" || temporalidad === "hora") {
      time = periodo + temporalidad
    }
  
    if (temporalidad === "m" || temporalidad === "min" || temporalidad === "minutes" || temporalidad === "minutos") {
      time = periodo + "min"
    }
  
    let data = {
      "wallet": wallet_orden,
      "resource": recurso,
      "amount": cantidad,
      "duration": time,
  
      "transaction": signedTransaction,
      "to_address": env.REACT_APP_WALLET_API,
      "precio": TronWeb.toSun(precio),
  
      "expire": Date.now() + (500 * 1000),
  
      "id_api": env.REACT_APP_USER_ID,
      "token": env.REACT_APP_TOKEN,
    }
  
  
    data = cryptr.encrypt(JSON.stringify(data));
  
    let consulta = await fetch(constantes.BRUTUS_API + "rent/energy", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ user: '9650f24d09200d8d0e1b31fd9eab8b55', data })
    }).then((r) => r.json())
      .catch((e) => {
        console.log(e.toString());
        return { result: false, hash: signedTransaction.txID, msg: "API-Error" }
      })
  
    return consulta
  
  }
  
  function getRandomInt(max) { return Math.floor(Math.random() * max); }

  export default {getRandomInt, rentResource}