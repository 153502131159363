import React, { Component } from "react";


class Footer extends Component {

    render() {
        return (<div className="footer-middle pt-20 pb-5">
            <div className="container">

                <div className="row footer-bottom mt-50">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="footer-bottom-content">
                            <div className="footer-bottom-content-copy">
                                <p>© CiroTrx all Rights Reserved. By: <span>Brutus.finance Team</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="footer-bottom-content">
                            <div className="footer-bottom-menu">
                                <ul>
                                    <li><a href="?white-apaper">White Paper</a></li>
                                    <li><a href="?privacy-policy">Privacy Policy</a></li>
                                    <li><a href="?terms-and-conditions">Terms & Conditions</a></li>
                                    <li id="version">Version: 1.2.24 </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
}

export default Footer